import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { MatomoProvider, createInstance } from '@jonkoops/matomo-tracker-react'
import Navigation from './Navbar';
import HomeComponent from './HomeComponent';

import './App.scss';

const CompareComponent = React.lazy(() => import('./CompareComponent'));
const StationMapComponent = React.lazy(() => import('./StationMapComponent'));
const ParamMapComponent = React.lazy(() => import('./ParamMapComponent'));
//const TempMapComponent = React.lazy(() => import('./TempMapComponent'));
//const HsMapComponent = React.lazy(() => import('./HsMapComponent'));
//const Hs24hMapComponent = React.lazy(() => import('./Hs24hMapComponent'));
//const WindMapComponent = React.lazy(() => import('./WindMapComponent'));
const HsGamComponent = React.lazy(() => import('./HsGamComponent'));
const ShadowMapComponent = React.lazy(() => import('./ShadowMapComponent'));
const SnowpackMapComponent = React.lazy(() => import('./SnowpackMapComponent'));

const instance = createInstance({
  urlBase: 'https://alpinemeteo.cr.baeren-lechtal.com/',
  siteId: 1,
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  configurations: { // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true
  }
});

const App = () => {

  return (
    <MatomoProvider value={instance}>
      <Router>
        <div>
          <Navigation />
          <Routes>
            <Route path="/" exact element={<HomeComponent />} />
            <Route path="/allstations" exact element={<StationMapComponent />} />
            <Route path="/parammaps" exact element={<ParamMapComponent />} />
            <Route path="/compare" exact element={<CompareComponent />} />
            <Route path="/hsgam" exact element={<HsGamComponent />} />
            <Route path="/shadow" exact element={<ShadowMapComponent />} />
            <Route path="/snowpack" exact element={<SnowpackMapComponent />} />   
            <Route path="*" element={<HomeComponent />} />   
          </Routes>
        </div>
      </Router>
    </MatomoProvider>
  );
};

export default App;

