import React from "react";
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { SocialIcon } from 'react-social-icons';
import { useTranslation } from "react-i18next";

const style = {
   position: 'absolute',
   top: '50%',
   left: '50%',
   transform: 'translate(-50%, -50%)',
   width: 250,
   bgcolor: 'background.paper',
   border: '2px solid #000',
   boxShadow: 24,
   p: 4,
 };

function Impressum(props) {
   const { t } = useTranslation();

   return (
      <Modal
         open={props.open}
         onClose={props.onClose}
         aria-labelledby="modal-modal-title"
         aria-describedby="modal-modal-description"
      >
         <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
               {t("contact.desc")}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
               <h6>Stefan Moosbrugger</h6>
               <h6>A-6654 Holzgau</h6>
               <h6>Holzgau 94c</h6>
               <h6><a href="mailto:info@alpinemeteo.com">info@alpinemeteo.com</a></h6>
               <SocialIcon style={{ height: 35, width: 35, margin: 5 }} url="mailto:info@alpinemeteo.com" />
               <SocialIcon style={{ height: 35, width: 35, margin: 5 }} url="https://www.linkedin.com/in/stefanmoosbrugger/" />
            </Typography>
         </Box>
      </Modal>);
}

export default Impressum;
